<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Candidates</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\
                  <span> Candidates </span>
                </div>
              </div>
              <!-- <div class="breadcrumb-right">
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <div class="navi navi-hover min-w-md-250px">
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="addFilter()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-filter"></i>
                                </span>
                          <span class="navi-text">Filters</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToExcel()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-excel"></i>
                                </span>
                          <span class="navi-text">Export (excel)</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="exportToCsv()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-csv"></i>
                                </span>
                          <span class="navi-text">Export (CSV)</span>
                        </a>
                      </b-dropdown-text>

                      <b-dropdown-text tag="div" class="navi-item">
                        <a @click="showSelectField()"  class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-database"></i>
                                </span>
                          <span class="navi-text">Export fields</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                  </b-dropdown>
                </div> -->
            </div>
          </div>

          <div class="card-body">
            <div
              class="
                search-wrapper
                border-outline
                mb-5
                px-3
                py-5
                d-sm-flex
                justify-content-start
              "
            >

              <div class="row">

                <v-col cols="12" sm="12" md="3">
                  <v-text-field
                      label="Search by name, UID, email, or phone"
                      v-model="search.info"
                      @input="search.info = $event !== null ? $event : ''"
                      v-on:keyup.enter="getCandidates"
                      outlined
                      dense
                      clearable
                  ></v-text-field>
                </v-col>


                <v-col
                    cols="12"
                    sm="6"
                    md="3"
                >
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.exam_key"
                      outlined
                      dense
                      @input="search.exam_key = $event !== null ? $event : ''"
                      clearable
                      v-on:keyup.enter="getCandidates"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="First name"
                      v-model="search.first_name"
                      outlined
                      dense
                      v-on:keyup.enter="getCandidates"
                      @input="search.first_name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Surname"
                      v-model="search.last_name"
                      outlined
                      dense
                      v-on:keyup.enter="getCandidates"
                      @input="search.last_name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="Class exam identifier"
                    v-model="search.exam_identifier"
                    outlined
                    dense
                    v-on:keyup.enter="getCandidates"
                    @input="
                      search.exam_identifier = $event !== null ? $event : ''
                    "
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">

                  <v-select
                      label="Exam type"
                      v-model="type"
                      outlined
                      dense
                      @input="type = $event !== null ? $event : 'all'"
                      clearable
                      :items="types"
                      item-text="name"
                      item-value="value"
                      v-on:keyup.enter="getCandidates"
                  ></v-select>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="3"
                  v-if="currentUser.access_type != 'score'"
                >
                  <v-select
                    label="SCORE"
                    v-model="search.score_id"
                    v-on:keyup.enter="getCandidates"
                    @input="search.score_id = $event !== null ? $event : '', handleScoreChange"
                    item-text="name"
                    item-value="id"
                    :items="scores"
                    @change="handleScoreChange"
                    outlined
                    dense
                    clearable
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="search.enrolment_year_id"
                    outlined
                    label="Year"
                    item-text="year"
                    :items="enrolment_years"
                    item-value="id"
                    v-on:keyup.enter="getCandidates"
                    dense
                    clearable
                  >
                  </v-select>
                </v-col>

                <v-col cols="12" sm="6"  md="3">
                  <v-autocomplete
                    @change="selectExam"
                    v-model="selectedExam"
                    outlined
                    dense
                    :items="exams"
                    hide-selected
                    item-text="display_text"
                    :search-input.sync="examSearch"
                    return-object
                    label="Exams"
                    placeholder="Enter exam name or subject code"
                    :loading="isLoading"
                    clearable
                  >
                    <template v-slot:label> Exam </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    label="Category"
                    v-model="search.category_id"
                    item-text="name"
                    v-on:keyup.enter="getCandidates()"
                    item-value="id"
                    @input="search.category_id = $event !== null ? $event : '', handleCategoryChange"
                    :items="categories"
                    outlined
                    clearable
                    @change="handleCategoryChange"
                    :loading="categoryLoading"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    label="Instrument "
                    v-model="search.instrument_id"
                    item-text="name"
                    v-on:keyup.enter="getCandidates()"
                    item-value="id"
                    :items="instruments"
                    @input="search.instrument_id = $event !== null ? $event : '', handleInstrumentChange"
                    outlined
                    clearable
                    @change="handleInstrumentChange"
                    :loading="instrumentLoading"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-select
                    label="Syllabus "
                    v-model="search.syllabus_id"
                    item-text="name"
                    @input="search.syllabus_id = $event !== null ? $event : ''"
                    v-on:keyup.enter="getCandidates()"
                    item-value="id"
                    :items="syllabuses"
                    outlined
                    clearable
                    :loading="isSyllabusLoading"
                    dense
                  ></v-select>
                </v-col>

                <v-col class="text-right" cols="2" :md="currentUser.access_type == 'score' ? 3 : 12">
                  <v-btn
                    @click.prevent="getCandidates()"
                    class="btn btn-primary text-white btn-search"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon
                    >&nbsp; Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div v-if="search.search_fields.length != 0" class="row mb-2">
              <v-col cols="12" md="9">
                <span class="subtitle font-weight-bold"> Filter applied </span>
                <span
                  v-for="(item, index) in search.search_fields"
                  v-if="item"
                  :key="index"
                  class="mx-2 badge badge-primary"
                >
                  {{ formatFilterName(item) }}
                </span>
              </v-col>
              <v-col cols="12" md="3" class="text-right">
                <v-btn @click="resetAppliedFilter" color="black" outlined
                  >Reset filter
                </v-btn>
              </v-col>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>

              <table v-if="!loading" class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">Surname</th>
                    <th class="px-3">First name</th>
                    <th class="px-3" style="max-width: 170px !important; white-space: pre-wrap;">Middle name</th>
                    <th class="px-3">Enrolment key</th>
                    <th class="px-3" style="max-width: 150px; white-space: pre-wrap">Exam name</th>
                    <th class="px-3" style="max-width: 200px !important; white-space: pre-wrap;">Exam status</th>
                    <th class="px-3">Year</th>
                    <th class="px-3" style="max-width: 250px; white-space: pre-wrap">Additional info</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="candidates.length != 0">
                    <tr v-for="(item, index) in candidates" :key="index">
                      <td class="px-3">
                        <div class="mb-1">
                          {{ item.last_name }}
                        </div>
                        <div v-if="item.check_refund_support_ticket_status">
                          <span
                            class="
                              block
                              px-5
                              text-center
                              ameb__bg-orange
                              rounded
                            "
                            v-if="
                              item.check_refund_support_ticket_status ==
                              'Pending'
                            "
                          >
                            Refund in progress
                          </span>
                          <span
                            class="
                              block
                              px-5
                              text-center
                              ameb__bg-blue
                              text-white
                              rounded
                              badge-assessed
                            "
                            v-if="
                              item.check_refund_support_ticket_status ==
                              'Completed'
                            "
                          >
                            Refunded
                          </span>
                        </div>
                      </td>
                      <td class="px-3">
                        <div class="mb-1">
                          {{ item.first_name }}
                        </div>
                      </td>
                      <td class="px-3" style="max-width: 170px !important; white-space: pre-wrap;">
                        <div class="mb-1">
                          {{ item.middle_name }}
                        </div>
                      </td>
                      <td class="px-3">
                        <div class="mb-1">
                          <a
                            href="#"
                            @click="getCandidateSummary(item.exam_key)"
                            class="
                              text-primary
                              font-weight-bolder
                              text-hover-primary
                              mb-1
                            "
                            ><u>{{ item.exam_key }} </u>
                          </a>
                        </div>
                      </td>
                      <td
                        class="px-3"
                        style="max-width: 150px; white-space: pre-wrap"
                      >
                        {{ item.exam_name }}
                      </td>
                      <td style="max-width: 200px !important; white-space: pre-wrap;" class="px-3">
                        <div v-html="item.enrolment_status_badge"></div>
                        <br />
                        <div v-html="item.exam_status_badge"></div>
                      </td>
                      <td class="px-3">
                        {{ item.enrolment_year }}
                      </td>

                      <td
                        class="px-3"
                        style="max-width: 280px; white-space: pre-wrap"
                      >
                        <div v-html="item.additional_info"></div>
                      </td>

                      <td class="pr-0 text-left">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  class="navi-link"
                                  @click="getCandidateSummary(item.exam_key)"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="darken-2">fas fa-eye</v-icon>
                                  </span>
                                  <span class="navi-text"
                                    >Candidate details</span
                                  >
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click="viewAccountHolderInfo(item.owner_id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="darken-2"
                                      >fas fa-user</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Enroller profile</span>
                                </a>
                              </b-dropdown-text>

                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td class="text-center" colspan="6">
                      No enrolments available
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                v-if="candidates.length > 0"
                class="pull-right mt-7"
                @input="getCandidates"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                :disabled="loading"
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- export fields -->
    <v-dialog scrollable v-model="searchFieldDialog" width="800px">
      <v-card>
        <v-card-title> Search filters </v-card-title>
        <v-card-text>
          <v-row class="mt-2" v-for="(row, index) in filters" :key="index">
            <v-col cols="12" md="4">
              <v-select
                v-model="row.search_fields"
                :items="candidateFiltersArray"
                item-text="text"
                item-value="value"
                label="Select Column"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                :items="conditionFilters"
                v-model="row.search_conditions"
                item-text="text"
                item-value="value"
                label="Conditions"
                outlined
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                label="Value"
                v-model="row.search_values"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-btn
                v-if="index == filters.length - 1"
                color="blue"
                class="text-white"
                @click="addFilterColumn()"
                fab
                small
              >
                <i class="fa fa-plus text-white"></i>
              </v-btn>
              <v-btn
                v-if="index || (!index && filters.length > 1)"
                @click="removeFilterColumn(index)"
                color="red"
                class="ml-2 text-white"
                fab
                small
              >
                <i class="fa fa-times text-white"></i>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-content-end">
          <v-btn @click="closeSelectFieldDialog" text x-large> Cancel </v-btn>
          <v-btn @click="applyFilter" class="btn btn-primary text-white">
            Apply filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="selectFieldDialog" width="700px">
      <v-card>
        <v-card-title> Select fields to appear on the excel file </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="(item, index) in candidateFilters"
              v-bind:key="item.id"
              cols="12"
              md="4"
            >
              <v-checkbox :label="index" :value="item" v-model="selectedFields" input-value="true">
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-spacer></v-spacer>

        <v-card-actions class="d-flex justify-content-end">

          <v-btn @click="closeExcelSelectFieldDialog" text x-large>
            Cancel
          </v-btn>
          <v-btn
              @click="selectFieldDialog = false"
              x-large
              dark

          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CandidatePlayListService from "@/services/candidate/candidate-playlist/CandidatePlayListService";
import CandidateService from "@/services/candidate/CandidateService";
import ScoreService from "@/services/score/score/ScoreService";
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import CandidateAttachmentService from "@/services/candidate/candidate-attachment/CandidateAttachmentService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";
import ProductService from "@/services/product/ProductService";
import CategoryService from "@/services/product/category/CategoryService";

const candidateAttachment = new CandidateAttachmentService();
const enrolmentYear = new EnrolmentYearService();
const candidate = new CandidateService();
const score = new ScoreService();
const candidatePlayList = new CandidatePlayListService();

const instrument = new InstrumentService();
const syllabus = new SyllabusService();
const product = new ProductService();
const category = new CategoryService();

export default {
  data() {
    return {
      isLoading: false,
      isExporting: false,
      isExportingExcel: false,
      candidates: [],
      total: null,
      title: "",
      perPage: null,
      page: null,
      examSearch: null,
      instruments: [],
      enrollments: [],
      sort: {
        key: "",
        isAsc: false,
      },
      search: {
        info:"",
        type: "",
        exam_key: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        exam_id: "",
        instrument_id: "",
        category_id: "",
        syllabus_id: "",
        enrolment_year_id: "",
        score_id: "",
        is_active: "",
        exam_status: "",
        enrolment_status: "",
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
      },
      selectedFields: [],
      selectedFilters: [],
      rows: [],
      filters: [
        {
          search_fields: "",
          search_conditions: "",
          search_values: "",
        },
      ],
      selectFieldDialog: false,
      searchFieldDialog: false,
      conditionFilters: [
        { text: "Equals To", value: "equals_to" },
        { text: "Less Than", value: "less_than" },
        { text: "Less Than Equals To", value: "less_than_equals_to" },
        { text: "Greater Than", value: "greater_than" },
        { text: "Greater Than Equals To", value: "greater_than_equals_to" },
        { text: "Contains", value: "contains" },
      ],
      loading: true,
      candidateFilters: [],
      candidateFiltersArray: [],
      scores: [],
      categories: [],
      enrolment_years: [],
      currentUserDetail: {},
      currentYear: new Date().getFullYear(),
      year: {},
      exams: [],
      syllabuses: [],
      instrumentLoading: false,
      categoryLoading: false,
      isSyllabusLoading: false,
      type: "all",
      types: [
        { name: "All", value: "all" },
        { name: "Theory exams", value: "online_exam" },
        { name: "Theory courses", value: "online_course" },
        { name: "Practical exams", value: "practical_exam" },
      ],
    };
  },
  methods: {
    addFilter() {
      this.searchFieldDialog = true;
    },
    viewPlaylistPdf(candidatePlaylistId) {
      candidatePlayList.viewPlaylistPdf(candidatePlaylistId);
    },
    viewNotificationLetter(examDayId, candidateId) {
      candidate.viewNotificationLetter(examDayId, candidateId);
    },
    getCandidates() {
      this.loading = true;
      this.search.type = this.type;

      candidate
        .paginate(this.search, this.page)
        .then((response) => {
          this.candidates = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch((err) => {
          this.$snotify.error("Oops looks like something went wrong.");
          this.searchFieldDialog = false;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCandidateSummary(examKey) {
      this.$router.push({
        name: "candidate-summary",
        params: { examKey: examKey },
      });
    },
    getExportFilters() {
      candidate
        .getExportFilters()
        .then((response) => {
          this.candidateFilters = response.data.candidateFilters;
          let candidateFilters = response.data.candidateFilters;
          candidateFilters = Object.entries(candidateFilters);

          candidateFilters.forEach(([key, value]) => {
            let data = {
              text: key,
              value: value,
            };
            this.candidateFiltersArray.push(data);
          });
        })
        .catch((err) => {});
    },
    getAllScore() {
      score.getScores().then((response) => {
        this.scores = response.data.scores;
      });
    },
    handleScoreChange() {
        this.categories = [];
        this.search.category_id = '';
        this.instruments = [];
        this.search.instrument_id = '';
        this.syllabuses = [];
        this.search.syllabus_id = '';
        if(this.search.score_id != ''){
            this.getCategoriesByScore();
        }
    },
    getCategoriesByScore(){
        this.categoryLoading = true;
        category
        .getByScore(this.search.score_id)
        .then((res)=>{
            this.categories = res.data.data;
            this.categoryLoading = false;
        })
        .catch((err) => {
            this.categoryLoading = false;
        })
    },
    handleCategoryChange(){
        this.instruments = [];
        this.search.instrument_id = '';
        this.syllabuses = [];
        this.search.syllabus_id = '';
        if(this.search.category_id != ''){
            this.getInstrumentsByCategory();
        }
    },
    getInstrumentsByCategory() {
        this.instrumentLoading = true;

        category
        .getInstruments(this.search.category_id)
        .then((response) => {
            this.instruments = response.data.instruments;
            this.instrumentLoading = false;
        })
        .catch((err) => {
            this.instrumentLoading = false;
        });
    },

    getAllSyllabuses() {
        if (this.search.instrument_id) {
            this.isSyllabusLoading = true;
            syllabus
            .getByInstrument(this.search.instrument_id)
            .then((response) => {
                this.syllabuses = response.data.data;
                this.isSyllabusLoading = false;
            })
            .catch((err) => {
                this.isSyllabusLoading = false;
            });
        }
    },
    applyFilter() {
      this.formatExportData();
      this.getCandidates();
      this.searchFieldDialog = false;
    },
    resetAppliedFilter() {
      this.filters = [
        {
          search_fields: "",
          search_conditions: "",
          search_values: "",
        },
      ];

      this.search.search_fields = [];
      this.search.search_values = [];
      this.search.search_conditions = [];

      this.applyFilter();
    },
    formatExportData() {
      this.search.selectedFields = this.selectedFields;
      let search_conditions = [];
      let search_fields = [];
      let search_values = [];

      this.filters.forEach((filter) => {
        if (filter.search_conditions)
          search_conditions.push(filter.search_conditions);
        if (filter.search_fields) search_fields.push(filter.search_fields);
        if (filter.search_values) search_values.push(filter.search_values);
      });

      this.search.search_conditions = search_conditions;
      this.search.search_fields = search_fields;
      this.search.search_values = search_values;
    },
    exportToCsv() {
      this.isExporting = true;
      this.formatExportData();

      candidate
        .exportToCsv(this.search)
        .then((response) => {
          this.$snotify.success(
            "Export has been started. Once completed you can view  on notification."
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.isExporting = false;
        });
    },
    exportToExcel() {
      this.isExportingExcel = true;
      this.formatExportData();

      candidate
        .exportToExcel(this.search)
        .then((response) => {
          this.$snotify.success(
            "Export has been started. Once completed you can view  on notification."
          );
        })
        .catch((error) => {})
        .finally(() => {
          this.isExportingExcel = false;
        });
    },
    closeSelectFieldDialog() {
      this.filters = [
        {
          search_fields: "",
          search_conditions: "",
          search_values: "",
        },
      ];
      this.search = {
        selectedFields: [],
        search_fields: [],
        search_conditions: [],
        search_values: [],
      };
      this.searchFieldDialog = false;
      this.getCandidates();
    },
    showSelectField() {
      this.selectFieldDialog = true;
    },
    closeExcelSelectFieldDialog() {
      this.selectFieldDialog = false;
      this.search.selectedFields = [];
      this.selectedFields = [];
    },
    addFilterColumn() {
      this.filters.push({
        search_fields: "",
        search_condition: "",
        search_values: "",
      });
    },
    removeFilterColumn(index) {
      this.filters.splice(index, 1);
    },

    getAllEnrolmentYears() {
      enrolmentYear.all().then((response) => {
        this.enrolment_years = response.data;
        // this.year = this.enrolment_years.filter(
        //   (item) => item.year == this.currentYear
        // );
        if (this.currentUser.access_type == "score") {
          // this.search.enrolment_year_id = this.currentUser.score_enrolment_year;
          this.getCandidates();
        } else {
          if (this.year.length > 0) {
            // this.search.enrolment_year_id = this.year[0].id;
            this.getCandidates();
          } else {
            this.getCandidates();
          }
        }
      });
    },
    selectedExam(val) {
      if (!val) this.search.exam_id = "";
    },
    selectExam() {
      if (this.selectedExam) this.search.exam_id = this.selectedExam.id;
    },
    viewAccountHolderInfo(accountHolderId) {
      this.$router.push({
        name: "accountholder-edit",
        params: { accountholderId: accountHolderId },
      });
    },
    manageReport(examKey) {
      this.$router.push({
        name: "practical-exam-result-report",
        params: { examKey: examKey },
      });
    },
    formatFilterName(name) {
      if (name) {
        name = name.replace("enrolments^", "");
        name = name.replace("products^","");
        name = name.replace("scores^","");
        
        return name.replace("_", " ").toUpperCase();
      }
    },
    addPlaylist(exam_key) {
      this.$router.push({
        name: "playlist-add",
        params: { examKey: exam_key },
      });
    },
    viewRepertoireAttachment(candidateId) {
      candidateAttachment
        .getByCandidate(candidateId)
        .then((response) => {
          let candidate_attachment = response.data.candidate_attachment;
          if (candidate_attachment.file_type == "pdf") {
            window.open(candidate_attachment.file_path.original, "_blank");
          } else {
            window.open(candidate_attachment.file_path.thumb, "_blank");
          }
        })
        .catch((err) => {})
        .finally(() => {});
    },

    addRepertoireAttachment(examKey) {
      this.$refs["upload-attachment"].openDialog(null, examKey);
    },
    requestRefund(candidate) {
      this.$refs["request-refund"].requestRefund(candidate);
    },
    handleInstrumentChange() {
        this.search.syllabus_id = '';
        this.syllabuses = '';
        if (this.search.instrument_id) {
            this.getAllSyllabuses();
        }
    },
  },
  mounted() {
    if (this.currentUser.access_type == "score") {
      this.search.score_id = this.currentUser.score_id;
      this.handleScoreChange();
    }
    this.getExportFilters();
    this.getAllScore();
    this.getAllEnrolmentYears();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  watch: {
    examSearch(val) {
      product
        .search(val)
        .then((response) => {
          response.data.products.map((product) => {
            let data = product;
            data.display_text = product.name + " | " + product.subject_code;
            this.exams.push(data);
          });
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>